import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getOzonProducts } from "../../../../../store/ShopsStore/oz"
import Preloader from "../../../../../components/Preloader"
import { useNavigate } from "react-router-dom"
import { getWBProducts } from "../../../../../store/ShopsStore/wb"
import { gatewayURL } from "../../../../../store/api"
import { setProductsParam } from "../../../../../store/ShopsStore/productsParams"

const ProductsShopList = ({code}) => {

    const dispatch = useDispatch()

    const products = useSelector((state) => state[code].products)
    const params = useSelector((state) => state.productsParams[code])

    console.log(params)

    useEffect(() => {
        if (params.load) {
            let brands = []

            if (params.brands.length > 0) {
                params.brands.forEach(e => {
                    brands.push(e.toString())
                })
            }
    
            const data = {
                sort : [
                    {
                        key: "name",
                        order: params.psName ? "asc" : "desc"
                    }
                ],
                filters: [
                    {
                        key: "active",
                        values: [
                            params.pfActive ? "true" : "false"
                        ]
                    },
                    {
                        key: "brands",
                        values: brands
                    }
                ],
                page: params.pagination.page
            }
    
            switch(code) {
                case "oz":
                    dispatch(getOzonProducts(data))
                    break
                case "wb":
                    dispatch(getWBProducts(data))
                    break
                default:
                    break
            }
            dispatch(setProductsParam(code, 'load', false))
        }

    }, [code, params.psName, params.load, params.brands, params.pfActive, params.pagination.page, dispatch])

    if (products === null) {
        return (
            <Preloader />
        )
    }
    return (
        <div className="list-view">
            {products.map((e,i) => <Item key={i} data={e} view={params.view} code={code} />)}
        </div>
    )
}

export default ProductsShopList

const Item = ({data, view, code}) => {

    const navigate = useNavigate()

    if (view) {
        return (
            <div className="view-small" onClick={() => navigate(`${data.ID}`)}>
                <div className="view-small-image">
                    <img src={`${gatewayURL}/api/v1/images/${code}/${data.ID}/${data.image}`} alt={data.title} />
                </div>
                <div className="view-small-inner">
                    <div className="view-small-article">{data.article}</div>
                    <div className="view-small-title">{data.title}</div>                
                </div>
            </div>
        )        
    }

    return (
        <div className="view-big" onClick={() => navigate(`${data.ID}`)}>
            <div className="view-big-title">{data.title}</div>
            <div className="view-big-image">
                <div className="view-big-article">{data.article}</div>
                <img src={`${gatewayURL}/api/v1/images/${code}/${data.ID}/${data.image}`} alt={data.title} />
            </div>
        </div>
    )
}
import { useSelector } from "react-redux"
import Preloader from "../../../../../components/Preloader"

const SaleDetails = () => {

    const sale = useSelector((state) => state.saleDetails)
    
    return (
        <div>
            <MainData data={sale.data} />
            <DetailsData data={sale.details} />
        </div>
    )
}

export default SaleDetails

const MainData = ({data}) => {
    return (
        <div>
            <h2>Подробнее об операции</h2>
            <table className="table-params">
                <tbody>
                    <tr>
                        <td>Дата</td>
                        <td>{data.date}</td>
                    </tr>
                    <tr>
                        <td>Товар</td>
                        <td>{data.item.title}</td>
                    </tr>
                    <tr>
                        <td>Реализован</td>
                        <td>{data.realisation ? data.realisation.toLocaleString('ru-RU') : 0}</td>
                    </tr>
                    <tr>
                        <td>Налог</td>
                        <td>{data.tax ? data.tax.toLocaleString('ru-RU') : 0}</td>
                    </tr>
                    <tr>
                        <td>Себестоимость</td>
                        <td>{data.purchase ? data.purchase.toLocaleString('ru-RU') : 0}</td>
                    </tr>
                    <tr>
                        <td>Выплата</td>
                        <td>{data.pay ? data.pay.toLocaleString('ru-RU') : 0}</td>
                    </tr>
                    <tr>
                        <td>Маржа</td>
                        <td>{data.margin ? data.margin.toLocaleString('ru-RU') : 0}</td>
                    </tr>
                    <tr>
                        <td>Маржинальность</td>
                        <td>{data.marginality ? data.marginality.toLocaleString('ru-RU') : 0}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

const DetailsData = ({data}) => {

    if (data === null) {
        return (
            <div>
                <Preloader />
            </div>
        )
    }

    if (typeof data === 'undefined') {
        return (
            <div>
                <h3>Операции</h3>
                Нет данных
            </div>
        )
    }

    return (
        <div>
            <h3>Операции</h3>
            <table className="table-params">
                <tbody>
                    {data.map((e, i) => <DataRow key={i} data={e} />)}
                </tbody>
            </table>

            
        </div>
    )
}

const DataRow = ({data}) => {
    console.log(data)
    return (
        <tr>
            <td>{data.date}</td>
            <td>{data.operationType}</td>
            <td>
                <span className={`level ${data.sum > 0 ? "good" : "critical"}`}>
                    {data.sum}
                </span>
            </td>
        </tr>
    )
}
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getOzonWidgetCount } from "../../../store/ShopsStore/oz"
import Preloader from "../../Preloader"
import { useNavigate } from "react-router-dom"
import { getWBWidgetCount } from "../../../store/ShopsStore/wb"

const ProductsCountWidgetShop = ({code}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const data = useSelector((state) => state[code].widgetCount)

    useEffect(() => {
        switch (code) {
            case "oz":
                dispatch(getOzonWidgetCount())
                break
            case "wb":
                dispatch(getWBWidgetCount())
                break    
            default:
                break
        }
    }, [code, dispatch])

    if (data === null || typeof data === "undefined") {
        return (
            <Preloader />
        )
    }

    return (
        <div className="widget-block linked" onClick={() => navigate(`products`)}>
            <h4>Товары</h4>
            <div className="widget-counter">{data}</div>
        </div>
    )
}

export default ProductsCountWidgetShop
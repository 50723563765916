import SaleDetails from "../../pages/App/ShopPage/SalesPage/SaleDetails"
import ModalFS from "../Modal/fullScreen"
import { useState } from "react"

const SalesListComp = ({sales, open, close, titleCol}) => {

    const [s, setS] = useState(false)

    const handlerOpen = (data) => {
        open(data)
        setS(true)
    }

    const handlerClose = () => {
        setS(false)
        close()
    }

    return (
        <>
            <div>
                <table className="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Дата</th>
                            {titleCol ? <th>Товар</th> : null}
                            <th>Реализация</th>
                            <th>Выплата</th>
                            <th>Маржа</th>
                            <th>Маржинальность</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sales.map((e, i) => <Row key={i} data={e} handlerOpen={handlerOpen} titleCol={titleCol} />)}
                    </tbody>
                </table>
            </div>
            {s ? <ModalFS close={handlerClose} body={<SaleDetails />} /> : null}
        </>
    )
}

export default SalesListComp

const Row = ({data, handlerOpen, titleCol}) => {
    return (
        <tr onClick={() => handlerOpen(data)}>
            <td><div className={data.final ? "dot final" : "dot pre"}></div></td>
            <td style={{textWrap: 'nowrap'}}>{data.date}</td>
            {titleCol ? <td>{data.item.title}</td> : null}
            <td>{data.realisation ? data.realisation.toLocaleString('ru-RU') : 0}</td>
            <td>{data.pay ? data.pay.toLocaleString('ru-RU') : 0}</td>
            <td>{data.margin ? data.margin.toLocaleString('ru-RU') : 0}</td>
            <td>
                <span className={`level ${data.marginLevel ?? null}`}>
                    {data.marginality ? data.marginality.toLocaleString('ru-RU') : 0}
                </span>
            </td>
        </tr>
    )
}
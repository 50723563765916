import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { getSystemShop } from "../../../../store/ShopsStore/systemShops"
import { setPageTitleBack, setPageTitleSubTitle, setPageTitleTitle } from "../../../../store/NavStore/title"
import FilterSales from "../../../../components/Filters/FilterSales"
import FromToSelector from "../../../../components/Filters/FromToSelector"
import SalesList from "./SalesList"
import { getOzonSales } from "../../../../store/ShopsStore/oz"
import { getWBSales } from "../../../../store/ShopsStore/wb"

const SalesPage = () => {

    const {code} = useParams()
    let loc = useLocation()
    const navigate = useNavigate()

    const queryParams = new URLSearchParams(window.location.search)
    const df = queryParams.get("df")
    const dt = queryParams.get("dt")
    
    const dispatch = useDispatch()

    const systemShops = useSelector((state) => state.systemShops.list)
    const shop = useSelector((state) => state.systemShops.shop)

    useEffect(() => {
        if (shop === null || shop.code !== code) {
            dispatch(getSystemShop(code))
        }
    }, [shop, code, dispatch, systemShops])

    useEffect(() => {
        if (shop !== null) {
            dispatch(setPageTitleTitle(shop.title))
            dispatch(setPageTitleSubTitle('Продажи'))
            dispatch(setPageTitleBack({name: 'Назад', link: `/app/${code}`}))
    
        }
    }, [dispatch, shop, code])

    const handlerDates = (from, to) => {
        navigate(`${loc.pathname}?df=${from}&dt=${to}`)
    }

    useEffect(() => {
        switch(code) {
            case "oz":
                dispatch(getOzonSales(df, dt))
                break
            case "wb":
                dispatch(getWBSales(df, dt))
                break
            default:
                break
        }
    }, [code, df, dt, dispatch])

    return (
        <>
        <div style={{display: "flex"}}>
            <div className="ws-sidebar">
                <FilterSales code={code} />
            </div>
            <div className="ws-main">
                <FromToSelector from={df} to={dt} handler={handlerDates} />
                <SalesList />
            </div>
        </div>
        </>
    )
}

export default SalesPage
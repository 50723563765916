import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getOzonWidgetSales } from "../../../store/ShopsStore/oz"
import { getWBWidgetSales } from "../../../store/ShopsStore/wb"
import Preloader from "../../Preloader"
import { useNavigate } from "react-router-dom"

const ShopWidgetRows = ({code, links}) => {

    const dispatch = useDispatch()

    const data = useSelector((state) => state[code].widgetSales)

    const [link, setLink] = useState(null)

    useEffect(() => {
        switch (code) {
            case "oz":
                dispatch(getOzonWidgetSales())
                break
            case "wb":
                dispatch(getWBWidgetSales())
                break    
            default:
                break
        }

        if (links) {
            setLink(`sales`)
        }
    }, [code, links, dispatch])

    if (data === null || typeof data === "undefined") {
        return (
            <Preloader />
        )
    }

    return (
        <>
            {data.map((e,i) => <Row key={i} data={e} link={link} />)}
        </>
    )
}

export default ShopWidgetRows

const Row = ({data, link}) => {

    const navigate = useNavigate()

    if (link && (data.count || data.sum)) {
        return (
            <div className="sales-count-row" style={{cursor: 'pointer'}} onClick={() => navigate(`${link}?df=${data.from}&dt=${data.to}`)}>
                <h5>{data.dayName}</h5>
                <div className="sales-count-block">
                    <div className="sales-count" style={{marginRight: 24}}>
                        <span>Продаж</span>
                        <div>{data.count ? data.count.toLocaleString('ru-RU') : 0}</div>
                    </div>
                    <div className="sales-count">
                        <span>На сумму</span>
                        <div>{data.sum ? data.sum.toLocaleString('ru-RU') : 0}</div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="sales-count-row">
                <h5>{data.dayName}</h5>
                <div className="sales-count-block">
                    <div className="sales-count" style={{marginRight: 24}}>
                        <span>Продаж</span>
                        <div>{data.count ? data.count.toLocaleString('ru-RU') : 0}</div>
                    </div>
                    <div className="sales-count">
                        <span>На сумму</span>
                        <div>{data.sum ? data.sum.toLocaleString('ru-RU') : 0}</div>
                    </div>
                </div>
            </div>
        )
    }
}